import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cp-admin-title-tab__wrapper" }
const _hoisted_2 = { class: "cp-badges-container" }
const _hoisted_3 = {
  key: 0,
  class: "cp-badge cp-badge_test"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tabs_nav = _resolveComponent("tabs-nav")!
  const _component_title_return = _resolveComponent("title-return")!
  const _component_filter_instant = _resolveComponent("filter-instant")!
  const _component_app_col = _resolveComponent("app-col")!
  const _component_gui_loader = _resolveComponent("gui-loader")!
  const _component_text_datetime = _resolveComponent("text-datetime")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_app_row = _resolveComponent("app-row")!

  return (_openBlock(), _createBlock(_component_app_row, {
    wrap: "nowrap",
    direction: "column",
    class: "cp-main-inner__wrapper"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_col, { grow: 0 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_tabs_nav, {
              items: _ctx.tabsNav,
              "active-id": _ctx.currentTab,
              params: { customerId: _ctx.customerId }
            }, null, 8, ["items", "active-id", "params"]),
            _createVNode(_component_title_return, {
              icon: "clients",
              "icon-size": "19",
              onClick: _ctx.returnBefore
            }, {
              "icon-badge": _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  (_ctx.isTest)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Test"))
                    : _createCommentVNode("", true)
                ])
              ]),
              default: _withCtx(() => [
                _createTextVNode(" " + _toDisplayString(_ctx.titlePage), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _createVNode(_component_filter_instant, {
            class: "cp-margin__t--medium",
            filters: _ctx.filterSettings.filterModel,
            onUpdateList: _ctx.updateList
          }, null, 8, ["filters", "onUpdateList"])
        ]),
        _: 1
      }),
      (Object.keys(_ctx.settings.table).length === 0)
        ? (_openBlock(), _createBlock(_component_app_col, {
            key: 0,
            grow: 1
          }, {
            default: _withCtx(() => [
              _createVNode(_component_gui_loader, { "absolute-center": "" })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (Object.values(_ctx.settings.table).length > 0)
        ? (_openBlock(), _createBlock(_component_app_table, {
            key: 1,
            class: "cp-margin__t--small grow-1",
            headers: _ctx.settings.table.titles,
            items: _ctx.settings.table.rows,
            page: _ctx.settings.table.pagination.currentPage,
            "total-pages": _ctx.settings.table.pagination.totalPages,
            "total-items": _ctx.settings.table.totalItems,
            "page-items": _ctx.settings.pageAmountItems && +_ctx.settings.pageAmountItems,
            onChangePage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.dispatch(_ctx.settings.actionPagination, $event))),
            onSelectAmount: _ctx.selectAmount,
            onSort: _ctx.sort
          }, {
            "item-createdAt": _withCtx(({ value }) => [
              value
                ? (_openBlock(), _createBlock(_component_text_datetime, {
                    key: 0,
                    value: value,
                    "format-to": "DD.MM.YYYY HH:mm:ss"
                  }, null, 8, ["value"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["headers", "items", "page", "total-pages", "total-items", "page-items", "onSelectAmount", "onSort"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}