import { Module, getModule, Action, Mutation } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import filterModel from './logsFilter';

import { getClientLogs, getClientLogsType } from '@/api/logs';
import { prepareList } from './logsTable';
import { PageSort } from '@/lib/layouts/page/page.interface';
import { TableInterface } from '@/lib/layouts/page/table.interface';
import CustomerLogsFilter from './logsFilterEntity';
import { Filter } from '@/lib/layouts/page/filter.interface';

import moment from 'moment';

const MODULE_NAME = 'customerLogs';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
export class CustomerLogsModule extends PageBaseModule {
  customerId = '';
  tableByShift: TableInterface = {} as TableInterface;
  isTableLoading = true;
  filter: Filter;

  constructor(module: CustomerLogsModule) {
    super(module);

    const page = new PageEntity();
    page.values.actionPagination = 'customerLogs/updatePage';
    this.pageSettings = page.values;

    const filter = new filterModel();
    this.filter = getModule(CustomerLogsFilter);
    this.filter.setFilterName('shopLogsFilter');
    this.filter.setTemplateClassName('template-sm');
    this.filter.setFilterModel(filter.filterModel);
    this.filter.setFilterHandlers(filter.filterHandlers);
  }

  @Mutation
  SET_CUSTOMER_ID(id: string) {
    this.customerId = id;
  }

  @Mutation
  SET_IS_TABLE_LOAD(bool: boolean) {
    this.isTableLoading = bool;
  }

  @Mutation
  SET_SORT(sorts: PageSort) {
    this.pageSettings.sort = sorts;
    window.localStorage.customersLogsSort = JSON.stringify(sorts);
  }

  @Mutation
  SET_TABLE_BY_SHIFT(table: TableInterface) {
    this.tableByShift = table;
  }

  @Action({ rawError: true })
  async init(customerId: string) {
    this.context.commit(
      'SET_SORT',
      window.localStorage.customersLogsSort ? JSON.parse(window.localStorage.customersLogsSort) : {}
    );
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    this.initListById(customerId);
  }

  @Action({ rawError: true })
  async initListById(customerId: string) {
    await this.context.commit('SET_CUSTOMER_ID', customerId);

    await this.filter.init();
    await this.getListById(customerId);
  }

  @Action({ rawError: true })
  async getListById(customerId: string) {
    const result = await this.requestById(customerId);
    this.setLogs(result);
  }

  @Action({ rawError: true })
  updateList() {
    this.getListById(this.customerId);
  }

  @Action({ rawError: true })
  async requestById(customerId: string) {
    try {
      const sort = await this.getSortForRequest();
      const filter = await this.getFilterForRequest(customerId);
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const result = await getClientLogs(this.pageSettings.pageCurrent, itemsQuery, sort, filter);

      return result.table;
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async getFilterForRequest(customerId: string) {
    const customerIdFilter = `&filters[0][id]=clientId&filters[0][value]=${customerId}`;

    return `${customerIdFilter}${this.filter.filterSettings.filter}`;
  }

  @Action({ rawError: true })
  setLogs(table: TableInterface) {
    const result = prepareList(table, this);
    this.context.commit('SET_TABLE', result);
  }

  @Action({ rawError: true })
  async initLogsByShift(customerId: string) {
    try {
      const result = await this.requestById(customerId);
      this.setShiftsLogs(result);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  setShiftsLogs(table: TableInterface) {
    const result = prepareList(table, this);
    this.context.commit('SET_TABLE_BY_SHIFT', result);
    this.updateIsShiftLogsLoading(false);
  }

  @Action({ rawError: true })
  updateIsShiftLogsLoading(bool: boolean) {
    this.context.commit('SET_IS_TABLE_LOAD', bool);
  }

  @Action({ rawError: true })
  async initTypesFilter() {
    const types: { type: string; humanType: string }[] = await getClientLogsType();
    const typesProcessed = Object.values(types).map((type: { type: string; humanType: string }) => {
      return {
        id: type.type,
        value: type.humanType,
      };
    });

    return typesProcessed;
  }

  @Action({ rawError: true })
  initDates() {
    const startDate = moment().startOf('month').format('DD/MM/YY').split('/');

    const today = moment().format('DD/MM/YY').split('/');

    return {
      startDate: {
        day: startDate[0],
        month: startDate[1],
        year: startDate[2],
      },
      endDate: { day: today[0], month: today[1], year: today[2] },
    };
  }

  @Action({ rawError: true })
  async updatePage(number: string) {
    await this.context.commit('SET_PAGE', parseInt(number));
    await this.getListById(this.customerId);
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', {
      moduleName: MODULE_NAME,
      amountItems: number,
    });
    await this.getListById(this.customerId);
  }

  @Action({ rawError: true })
  async sort(params: { field: string; sort: string }) {
    const sortProcessed = await this.context.dispatch('sortProcessed', params);

    this.context.commit('SET_SORT', sortProcessed);
    await this.getListById(this.customerId);
  }
}

export default getModule(CustomerLogsModule);
