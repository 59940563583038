import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class LogsEmployeesFilter {
  filterHandlers = {
    update: 'customerLogsFilter/updateFilter',
    reset: 'customerLogsFilter/resetFilter',
    resetState: 'customerLogsFilter/resetFilterState',
    removeSelected: 'customerLogsFilter/removeSelectedFilter',
  };
  filterModel: {};

  constructor() {
    const filter = new FilterFactory();

    filter.createDateIntervalPickerEntity('date');
    filter.setName('date', 'picker_date');
    filter.setTitle('date', '');
    filter.setInitAction('date', 'customerLogs/initDates');
    filter.setDateIntervalList('date', {
      startDate: {
        key: 'periodStart',
        type: 'date-picker',
        title: '',
        placeholder: '',
        value: '',
        action: 'customerLogsFilter/updateStartDate',
      },
      endDate: {
        key: 'periodEnd',
        type: 'date-picker',
        title: '',
        placeholder: '',
        value: '',
        action: 'customerLogsFilter/updateEndDate',
      },
    });
    filter.setPreviewName('date', 'Дата');
    filter.setIconClassName('date', 'icon-clock');

    filter.createSelectEntity('type');
    filter.setTitle('type', 'Тип события');
    filter.setName('type', 'select_type');
    filter.setInitAction('type', 'customerLogs/initTypesFilter');
    filter.setPreviewName('type', 'Тип');
    filter.setAction('type', 'customerLogsFilter/updateSelect');

    this.filterModel = filter.filterModel;
  }
}
