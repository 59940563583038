import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { UITable } from '@/lib/util/tableUtils';
import { CustomerLogsModule } from '@/store/customers/logs';

/**
 * Returns processed client logs table
 *
 * @param {Object} table client logs data
 * @returns {Object} tableInfo
 */
export function prepareList(table: TableApiInterface, state: CustomerLogsModule) {
  const uiTable = new UITable(table as any);
  const sorts = Object.values(state.pageSettings.sort) as { sort: string; key: string }[];

  return uiTable
    .removeColumn('id')
    .removeColumn('type')
    .removeColumn('clientId')

    .setSortableValues(sorts)

    .getTable() as any;
}
