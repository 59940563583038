
import { Component, Vue } from '@/lib/decorator';
import TitleReturn from '@/components/TitleReturn.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import Loading from '@/components/Loading.vue';

import PaginationBlock from '@/components/Pagination.vue';
import FilterInstant from '@/components/FilterInstant.vue';

import CustomerModule from '@/store/customers';
import CustomerEntityModule from '@/store/customers/entity';
import CustomerLogsModule from '@/store/customers/logs';
import TextDatetime from '@/components/table-items/TextDatetime.vue';
import AppTable from '@/components/ui/table/Table.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import { PageInterface } from '@/lib/layouts/page/page.interface';
import { FilterSettings } from '@/lib/layouts/page/filter.interface';
import { TabsNavInterface } from '@/interfaces/tabs.interface';
import { GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    AppCol,
    AppRow,
    AppTable,
    TextDatetime,
    Loading,
    TitleReturn,
    TabsNav,
    PaginationBlock,
    FilterInstant,
  },
  beforeMount() {
    this.customerId = this.$route.params.customerId;
  },
})
export default class EmployeesEntityIndex extends Vue {
  customerId = '';

  get isTest(): boolean {
    return CustomerEntityModule.model.isTest;
  }

  get settings(): PageInterface {
    return CustomerLogsModule.pageSettings;
  }

  get filterSettings(): FilterSettings {
    return CustomerLogsModule.filter.filterSettings;
  }

  get titlePage(): string {
    return CustomerModule.pageSettings.titleEdit;
  }

  get isLoading(): boolean {
    return CustomerEntityModule.isLoading;
  }

  get tabsNav(): TabsNavInterface {
    return CustomerEntityModule.tabsNav;
  }

  get currentTab(): string {
    return CustomerEntityModule.allTabs.logs?.id;
  }

  returnBefore(): void {
    this.$router.push({ name: 'customers' });
  }

  selectAmount(value: string): void {
    CustomerLogsModule.updatePageAmountItems(value);
  }

  sort(header: { id: string; sort: { value: string } }): void {
    CustomerLogsModule.sort({ field: header.id, sort: header.sort.value || '' });
  }

  async updateList(): Promise<void> {
    await CustomerLogsModule.setPageToBegin();
    await CustomerLogsModule.filter.updateFilter();
    await CustomerLogsModule.getListById(this.customerId);
  }

  mounted(): void {
    CustomerEntityModule.initTabsItems();
    CustomerEntityModule.initTitle(this.customerId);
    CustomerLogsModule.init(this.customerId);
  }
}
